/** Here to Work: Mining Video **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data;

class HereToWorkPage5 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[5];

    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);

    //show text
    gsap.to(this.refs.text1Ref, 0, { autoAlpha: 1.0 });

    //add base track
    globalState.baseAudioRef.updateTrack(1, false);

    //If coming to this chapter by clicking prev
    if(globalState.transitionDirection === "prev") {
      globalState.baseAudioRef.updateTrack(2, globalState.cdnUrl + data.soundfile2, 80); //base track
    } else {
      //update volume for chapter base track
      globalState.baseAudioRef.updateVolume(2, 80);
    }

    

    //Preload assets from next page
    //const preloadData = globalState.assets[4];
    preloadImages([globalState.cdnUrl + data.nextTitle], this.preloadCallback);

  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }



  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    //remove base track
    globalState.baseAudioRef.updateTrack(1, false);

  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
    this.bgVideoRef.muteVideo();
    this.bgVideoRef.seekVideo(35);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "left", "top", globalState.textPosition.leftTop);

    } else {
      //hide text
      hideText(this.refs.text1Ref, "left");
    }
  }



  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }



  render() {

    return (

      <>

        <link rel="prefetch" href="/shelf-life" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/shelf-life" prevLocation="/here-to-work/4"
          isTransitioning={this.isTransitioning} />
        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Here To Work" />

          {data.bgVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
              poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={false} muted={false} ended={this.bgVideoEnded} ref={div => this.bgVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }

          <div className="text-wrapper">
            <div className="text text--left-top" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginLeft: "0", marginTop: "0" }} onClick={this.toggleTextDisplay} alt="Did the fact that their task was so intensely primal – digging raw materials out of the earth – sharpen their memories of the place? Did anyone ever pause to think that the work of Pine Point was a metaphor for the future of the town? It would live on only for those willing to dig into history, into memory." />
            </div>
          </div>

        </div>
        )}
      </>
    );
  }


};

export default HereToWorkPage5
